import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ScriptTag from 'react-script-tag';
import {Box, Flex} from "theme-ui";

const styles = {

    searchContainer: {
        flexBasis: [`auto`, null, `1/3`],
        minWidth: `300px`,
        order: [3, null, `unset`],
        mx: 3,
        minHeight: `300px`
    }
}

const myHTML = `<div class="gcse-searchresults-only"></div>`;

const Search = props => (
    <Layout {...props}>
        <Seo title='Search Results' />
        <Divider />
        <Stack>
            <Main>
                <Section>
                    <PageTitle
                        header="Search Results"
                        subheader=''
                    />
                </Section>
                <Section>
                    <Box id="cmSearch">
                        <ScriptTag type="text/javascript" async src="https://cse.google.com/cse.js?cx=41556f23372ed4949" />
                        <div dangerouslySetInnerHTML={{ __html: myHTML }} />


                    </Box>
                </Section>
            </Main>
        </Stack>
    </Layout>
)

export default Search
